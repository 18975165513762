import React from 'react';
import styles from './HeaderMenu.module.scss';
import {Link} from "react-router-dom";

const HeaderMenu = () => {
    return (
        <div className={styles.HeaderMenu}>
            <Link className={styles.MenuItem} to="/order-processing">Pregled porudžbina</Link>
            <Link className={styles.MenuItem} to="/rfzo-patient-reminder">RFZO pacijenti</Link>
            <Link className={styles.MenuItem} to="/rfzo-article-reminder">RFZO artikli</Link>
            <Link className={styles.MenuItem} to="/patients">Svi pacijenti</Link>
            <Link className={styles.MenuItem} to="/statistics">Statistika</Link>
        </div>
    );
};

export default HeaderMenu;
