import React from 'react';
import styles from './Checkbox.module.scss'

type Props = {
    label?: string,
    value: boolean,
    onChange: React.ReactEventHandler
}

const Checkbox = ({label, value, onChange}:Props) => {

    return (
        <div className={styles.CheckboxWrapper}>
            <input type="checkbox"
                   id="checkbox"
                   checked={value}
                   onChange={onChange}
            />
            <label htmlFor="checkbox">&nbsp;{label}</label>
        </div>
    );
};

export default Checkbox;