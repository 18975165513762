import React, {useEffect} from 'react';
import './App.scss';
import {Route, Routes, useNavigate} from "react-router-dom";
import AuthPage from "./pages/AuthPage/AuthPage";
import OrderProcessingPage from "./pages/OrderProcessingPage/OrderProcessingPage";
import PharmacistSupportPage from "./pages/PharmacistSupportPage/PharmacistSupportPage";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {fetchMarketplaceInfo, loginCheckState} from "./store/auth-actions";
import ArticlesOutletPage from "./pages/ArticlesOutletPage/ArticlesOutletPage";
import CommercialsPage from "./pages/CommercialsPage/CommercialsPage";
import Layout from "./components/Layout/Layout";
import ArticlesVisibilityPage from "./pages/ArticlesVisibilityPage/ArticlesVisibilityPage";
import RFZOReminderPage from "./pages/RFZOReminderPage/RFZOReminderPage";
import OurPatientsPage from "./pages/OurPatientsPage/OurPatientsPage";
import PushNotificationsPage from "./pages/PushNotificationsPage/PushNotificationsPage";
import Statistics from './pages/Statistics/Statistics';

function App() {

    const dispatch = useDispatch();

    const isLogin = useSelector((state: RootState) => state.auth.token)
    const isPharmacist = useSelector((state:RootState) => state.auth.isPharmacist)
    const navigate = useNavigate();

    useEffect(() => {
        dispatch<any>(loginCheckState());
        dispatch<any>(fetchMarketplaceInfo(parseInt(localStorage.getItem('id')!)));
    }, [])

    useEffect(() => {
        if (isLogin !== null) {
            if (isPharmacist || localStorage.getItem("roles")?.includes('PHARMACIST_SUPPORT_USER')) {
                navigate("/pharmacist-support")
            } else {
                navigate("/order-processing")
            }
        } else {
            navigate('/auth');
        }
    }, [isLogin])

    let routes = (
        <Routes>
            <Route path="/auth/*" element={<AuthPage/>}/>
            <Route path="/order-processing" element={<OrderProcessingPage/>}/>
            <Route path="/pharmacist-support" element={<PharmacistSupportPage/>}/>
            <Route path="/articles-outlet" element={<ArticlesOutletPage/>}></Route>
            <Route path="/articles-visibility" element={<ArticlesVisibilityPage/>}></Route>
            <Route path="/web-commercials" element={<CommercialsPage web/>}></Route>
            <Route path="/mobile-commercials" element={<CommercialsPage mobile/>}></Route>
            <Route path="/rfzo-patient-reminder" element={<RFZOReminderPage rfzoPatients/>}></Route>
            <Route path="/rfzo-article-reminder" element={<RFZOReminderPage rfzoArticles/>}></Route>
            <Route path="/patients" element={<OurPatientsPage />}></Route>
            <Route path="/statistics" element={<Statistics />}></Route>
            <Route path="/push-notifications" element={<PushNotificationsPage />}></Route>
        </Routes>
    );
  return (
    <Layout>
        {routes}
    </Layout>
  );
}

export default App;