import React, { useEffect, useState } from "react";
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import ArchivedOrderBox from "../../ArchivedOrderBox/ArchivedOrderBox";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import PerfectScrollbar from "react-perfect-scrollbar";
import filterIll from "../../../../assets/icons/filter-ilustration.svg";
import { Filters } from "../../../../pages/Statistics/Statistics";

type Props = {
  isAdmin?: boolean;
};
const ArchivedColumn = ({ isAdmin }: Props) => {
  const orderState = useSelector((state: RootState) => state.order);
  const rfzoTherapy = useSelector((state: RootState) => state.rfzoTherapy);

  const [filters, setFilters] = useState({
    status: "ALL",
  });
  const [orders, setOrders] = useState<any>(<div>Waiting...</div>);
  const [rfzoRequest, setRfzoRequest] = useState<any>("");

  const filterOrders = (statuses: string, orderState: any[]) => {
    if(statuses == "ALL") {
        return orderState
    }
    return orderState.filter((order): any => statuses.includes(order.status));
  };
  const filterRfzoOrders = (statuses: string, orderState: any[]) => {
    if(statuses == "ALL") {
        return orderState
    }
    if(statuses.includes("CANCELED")) {
        return orderState.filter((order): any => statuses.includes(order.status));
    }
    return orderState.filter((order): any => statuses.includes(order.status));
  };

  useEffect(() => {
    if (!orderState.loadingOrders) {
      if (orderState.ordersFailed) {
        //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
      } else {

        const filteredOrders = filterOrders(filters.status, orderState.finishedOrders) || [];

        setOrders(
          filteredOrders.map((order: any) => {
              return <OrderBox archived={true} key={order.id} order={order} isAdmin={isAdmin} />;
            })
        );
      }
    }
  }, [orderState.finishedOrders, orderState.loadingOrders, filters]);

  useEffect(() => {
    if (!rfzoTherapy.loadingRFZOTherapies) {
      if (rfzoTherapy.rfzoTherapyRequestsFailed) {
        //todo ovde obrada greske priliko ucitavanja rfzo zahteva
      } else {
        if (rfzoTherapy.rfzoTherapyRequests.length >= 0){
        const filteredOrders = filterRfzoOrders(filters.status, rfzoTherapy.rfzoTherapyRequests) || [];
          setRfzoRequest(
            rfzoTherapy.rfzoTherapyRequests.map((rfzoRequest: any, index: number) => {
              if (rfzoRequest.status !== "REQUESTED" && rfzoRequest.status !== "PROCESSED") {
                return <OrderBox archived={true} key={index} order={rfzoRequest} isAdmin={isAdmin} />;
              }
            })
          );}
      }
    }
  }, [rfzoTherapy.rfzoTherapyRequests, rfzoTherapy.loadingRFZOTherapies]);

  const rfzoRequestRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
    return rfzoRequest.status !== "REQUESTED" && rfzoRequest.status !== "PROCESSED";
  });

  const [showFilters, setShowFilters] = useState(false);


  return (
    <ColumnComponent
      leftSideLabel="Obrađene"
      leftSideNumber={(orderState.finishedOrders.length + rfzoRequestRequested.length).toString()}
      bodyStyle={{ backgroundColor: "transparent", border: "none" }}
      style={{ flex: "4" }}>
      <div
        onClick={() => setShowFilters(true)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          cursor: "pointer",
        }}>
        <img width={24} src={filterIll} />
      </div>
      {showFilters && (
        <Filters showDate={false} filters={filters} setFilters={setFilters} hideModal={() => setShowFilters(false)} />
      )}
      <PerfectScrollbar style={{ padding: "4px" }}>
        {orders}
        {rfzoRequest}
      </PerfectScrollbar>
    </ColumnComponent>
  );
};

export default ArchivedColumn;
