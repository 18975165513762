import React, { useContext, useState } from "react";
import styles from "./SearchBarMultiple.module.scss";
import { FuzzySortContext } from "../../../../../../store/context/FuzzySort/FuzzySort";
import Backdrop from "../../../../Backdrop/Backdrop";
import SearchItemsMultiple from "../SearchItemsMultiple/SearchItemsMultiple";
import Button from "../../../../Buttons/Button/Button";

type Props = {
  data: any;
  noBackdrop?: boolean;
  onConfirm?: any;
  selectedItemsInitial?: any;
};

const SearchBarMultiple = ({ data, onConfirm, selectedItemsInitial }: Props) => {
  const [searchStr, setSearchStr] = useState("");
  const [searchRes, setSearchRes] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const fuzzySort = useContext(FuzzySortContext);
  const handleChange = (e: any) => {
    setShowSearchResults(true);
    setSearchStr(e.target.value);
    if (e.target.value.length >= 3) {
      setTimeout(() => {
        setSearchRes(e.target.value ? filterItems() : []);
      }, 600);
    } else {
      setShowSearchResults(false);
    }
    if (e.target.value === "") {
      setShowSearchResults(false);
    }
  };

  const resetSearch = () => {
    // !props.diagnosis && setShowSearchResults(false);
    setShowSearchResults(false);
    setSearchStr("");
    setSearchRes(null);
  };

  const filterItems = () => {
    const searchStringWords = searchStr.split(" ").filter((string) => {
      if (string.trim() !== "") {
        return string.trim();
      }
    });

    let results = fuzzySort.filterByWords(data, searchStringWords, 0);

    results = fuzzySort.sortResultsByStartWord(results, searchStringWords[0]);

    return results;
  };

  const backdropClickHandler = () => {
    setShowSearchResults(false);
    // setSearchStr("");
  };

  return (
    <div className={styles.searchWrap}>
      <input
        className={styles.SearchBarInput}
        placeholder={"Pretražite artikle"}
        type="text"
        name="search-box"
        value={searchStr}
        onChange={(event: any) => handleChange(event)}
        autoComplete="off"
      />
      <SearchItemsMultiple
        selectedItemsInitial={selectedItemsInitial}
        onConfirm={onConfirm}
        className={styles.SearchItems}
        style={
          !showSearchResults || searchRes === null || searchRes === " "
            ? { display: "none" }
            : { zIndex: 1, backgroundColor: "white" }
        }
        results={searchRes}
        close={() => {}}
      />
      <Backdrop
        zIndex="-1"
        show={showSearchResults}
        backdropClickHandler={backdropClickHandler}
        backgroundColor="transparent"
        className={styles.SearchBarBackdrop}
      />
    </div>
  );
};

export default SearchBarMultiple;
