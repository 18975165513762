import React, { useEffect, useState } from "react";
import styles from "./PromoCategories.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../UI/Buttons/Button/Button";
import { RootState } from "../../../store";
import TextField from "../../TextField/TextField";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { fetchItemNames } from "../../../store/rfzoTherapy-actions";
import SearchBarMultiple from "../../UI/Modals/RFZOTherapyRequestModal/SearchWrapper/SearchBarMultiple/SearchBarMultiple";
import axios from "../../../axios/axios";
import { baseUrl } from "../../../axios/axios";
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import editIcon from "../../../assets/icons/edit.svg";
import deleteIcon from "../../../assets/icons/trash.svg";
import ImageUploaderWrap from "./ImageUploaderWrap";
import classNames from "classnames";
import NotificationMessage from "../../UI/NotificationMessage/NotificationMessage";

const Row = ({ children, style }: any) => {
  return <div style={{ display: "flex", alignItems: "center", gap: 5, ...style }}>{children}</div>;
};

const PromoCategories = () => {
  const [allCategories, setAllCategories] = useState([]);

  const fetchCategories = () => {
    getAllCategories().then((res) => setAllCategories(res.data));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [editCatData, setEditCatData] = useState();

  const editCategory = (cat: any) => {
    setEditCatData(cat);
  };

  return (
    <div className={styles.categoriesWrap}>
      <div className={styles.header}>Nove kategorije</div>

      <SingleCategory initialData={editCatData} refetch={fetchCategories} />
      <CategoriesList
        editCategory={editCategory}
        refetch={fetchCategories}
        setAllCategories={setAllCategories}
        allCategories={allCategories}
      />
    </div>
  );
};

export default PromoCategories;

const SingleCategory = ({ initialData, onCancel, refetch }: any) => {
  const dispatch = useDispatch();

  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);

  const successfulNotification = () => {
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  useEffect(() => {
    setInitImageList(
      initialData?.images?.map((i: any) => process.env.REACT_APP_CDN_PATH + "/item_list_images/" + i?.imageLink) || []
    );
    setSelectedItems(initialData?.items?.map((i: any) => i?.itemResponse) || []);
    setCategoryData(initialData);
  }, [initialData]);

  const itemNames = useSelector((state: RootState) => state.rfzoTherapy.itemNames);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryData, setCategoryData] = useState<any>({});
  const [imageList, setImageList] = useState([]);
  const [initImageList, setInitImageList] = useState([]);

  const cancelWrap = () => {
    if (initialData) {
      onCancel();
    } else {
      setSelectedItems([]);
      setCategoryData({});
      setImageList([]);
      setInitImageList([]);
    }
  };

  useEffect(() => {
    dispatch<any>(fetchItemNames());
  }, []);

  const createCategoryWrap = () => {
    if (!initialData) {
      createCategory({ ...categoryData, items: selectedItems.map((item: any) => item.id) }, imageList).then((res) => {
        successfulNotification();
        cancelWrap();
        refetch();
      });
    } else {
      const imageArray = initImageList.length > 0 ? [] : imageList;
      updateCategory({ ...categoryData, items: selectedItems.map((item: any) => item.id) }, imageArray).then((res) => {
        successfulNotification();
        cancelWrap();
        refetch();
      });
    }
  };

  return (
    <div>
      {notificationMessageVisible && <NotificationMessage>Uspesno</NotificationMessage>}

      <div className={styles.subHeader}>{initialData ? "Izmeni" : "Dodaj"} standardnu kategoriju</div>
      <TextField
        label="Naziv kategorije"
        value={categoryData?.name || ""}
        placeHolder=""
        onChange={(val: any) => {
          setCategoryData((prev: any) => ({ ...prev, name: val }));
        }}
      />
      {initImageList.length > 0 ? (
        <CurrentImage image={initImageList[0]} onImageUpdate={() => setInitImageList([])} />
      ) : (
        <ImageUploaderWrap
          label="Naslovna strana kategorije"
          initImageList={initImageList}
          imageList={imageList}
          setImageList={setImageList}
        />
      )}
      <ItemPickingModal
        selectedItemsInitial={selectedItems}
        onConfirm={(val: any) => setSelectedItems(val)}
        itemNames={itemNames}
      />

      <div className={styles.checkboxWrap}>
        <Checkbox
          onChange={(val) => {
            setCategoryData((prev: any) => ({ ...prev, priority: !prev.priority }));
          }}
          value={categoryData?.priority || false}
        />
        Označi kategoriju kao prioritetnu
      </div>

      {selectedItems.length > 0 &&
        selectedItems?.map((item: any) => <div style={{ marginBottom: "5px" }}>{item?.name}</div>)}
      <Row style={{ marginTop: "20px" }}>
        <Button onClick={() => cancelWrap()} buttonType="white">
          Odustani
        </Button>
        <Button disabled={!categoryData?.name} onClick={() => createCategoryWrap()} buttonType="blue">
          Sacuvaj
        </Button>
      </Row>
    </div>
  );
};
const CategoriesList = ({ allCategories, refetch, editCategory }: any) => {
  if (allCategories.length === 0) {
    return <div className={styles.noCategories}>Trenutno nema kategorija</div>;
  }

  return (
    <div className={styles.categoriesListWrap}>
      <div className={styles.subHeader}>Lista kategorija</div>
      <div>
        {allCategories?.map((cat: any) => (
          <CategoryItem
            refetch={refetch}
            cat={cat}
            editCategory={editCategory}
            onDelete={async () => {
              await deleteCategory(cat?.id);
              refetch();
            }}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryItem = ({ cat, onDelete, refetch }: any) => {
  const [editCategory, setEditCategory] = useState(false);

  return (
    <div
      className={classNames(styles.categoryItemWrap, {
        [styles.editMode]: editCategory,
      })}>
      <div className={styles.category}>
        <div className={styles.categoryName}>{cat?.name}</div>
        <div>
          <img alt="icon" onClick={() => setEditCategory(true)} src={editIcon} />
          <img alt="icon" onClick={onDelete} src={deleteIcon} />
        </div>
      </div>
      {editCategory && <SingleCategory refetch={refetch} onCancel={() => setEditCategory(false)} initialData={cat} />}
    </div>
  );
};

const ItemPickingModal = ({ itemNames, onConfirm, selectedItemsInitial }: any) => {
  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <Button className={styles.addItemsButton} buttonType="white" onClick={() => setShow(true)}>
        Izaberi proizvode
      </Button>
    );
  }

  return (
    <SimpleModal backdropClickHandler={() => setShow(false)} show={show} className={styles.Modal}>
      <SearchBarMultiple
        selectedItemsInitial={selectedItemsInitial}
        onConfirm={(val: any) => {
          onConfirm(val);
          setShow(false);
        }}
        data={itemNames}
      />
    </SimpleModal>
  );
};

const createCategory = (data: any, imageList: any) => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  const imagesNames = imageList.map((img: any) => {
    console.log(img);
    return {
      imageLink: img?.file?.name,
      priority: 1,
      isSmall: 0,
    };
  });

  const formData = new FormData();
  const payload = {
    marketplaceId: marketplaceId,
    branchMarketplaceId: branchMarketplaceId,
    name: data.name,
    priority: 1,
    displayOnProfile: data.displayOnProfile,
    promotion: data.promotion,
    items: data.items?.map((item: any) => ({ id: item })),
    images: imagesNames,
  };
  formData.append("itemListRequestString", JSON.stringify(payload));
  imageList.forEach((i: any) => formData.append("images", i?.file));

  return axios.post(baseUrl + "/item_list", formData);
};
const updateCategory = (data: any, imageList: any) => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  const imagesNames = imageList.map((img: any) => {
    console.log(img);
    return {
      imageLink: img?.file?.name,
      priority: 1,
      isSmall: 0,
    };
  });

  const formData = new FormData();
  const payload = {
    ...data,
    marketplaceId: marketplaceId,
    branchMarketplaceId: branchMarketplaceId,
    name: data.name,
    priority: 1,
    displayOnProfile: data.displayOnProfile,
    promotion: data.promotion,
    items: data.items?.map((item: any) => ({ id: item })),
  };
  if (imageList.length > 0) {
    payload.images = imagesNames;
  }
  formData.append("itemListRequestString", JSON.stringify(payload));
  imageList.forEach((i: any) => formData.append("images", i?.file));
  return axios.put(baseUrl + "/item_list", formData);
};

const getAllCategories = () => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  return axios.get(
    baseUrl +
      `/item_list?filter=marketplace.id.marketplaceId:${marketplaceId} and marketplace.id.branchMarketplaceId:${branchMarketplaceId}&returnItems=true`
  );
};

const deleteCategory = (id: any) => {
  return axios.delete(baseUrl + `/item_list/item_list_id/${id}`);
};

const CurrentImage = ({ image, onImageUpdate }: any) => {
  return (
    <div className={styles.imageWrap}>
      <img className={styles.image} src={image} />
      <Button className={styles.changeImageButton} buttonType="white" onClick={() => onImageUpdate()}>
        Promeni sliku
      </Button>
    </div>
  );
};
