import React from "react";
import styles from "./TextField.module.scss";
import classNames from "classnames";

function TextField({ type = "text", className, label, labelClassName, placeHolder, onChange, name, ...rest }: any) {
  return (
    <div className={styles.inputWrap}>
      {label && (
        <label htmlFor={name} className={classNames(labelClassName, styles.labelClass)}>
          {label}
        </label>
      )}
      <input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeHolder}
        className={classNames(styles.inputClass, className)}
        type={type}
        {...rest}
      />
    </div>
  );
}

export default TextField;
