import React from "react";
import styles from "./PromoCategories.module.scss";
import Button from "../../UI/Buttons/Button/Button";
import ImageUploading from "react-images-uploading";
import uploaderImage from "../../../assets/icons/uploader.svg";

const ImageUploaderWrap = ({ initImageList, imageList, setImageList, label }: any) => {
  const onChange = (value: any) => {
    setImageList(value);
  };

  console.log(initImageList)

  return (
    <ImageUploading multiple value={imageList} onChange={onChange} maxNumber={2}>
      {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps, onImageRemove }) => (
        <div className={styles.uploadWrapper}>
          <label>{label}</label>
          {initImageList.length == 0  && imageList.length === 0 && (
            <div
              className={styles.imageUploadField}
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}>
              <img height={64} width={64} src={uploaderImage} />
              <div>Dodajte fotografiju ili je prevucite u polje.</div>
            </div>
          )}
          {initImageList.length == 0 && imageList.map((image, index) => (
            <div key={index} className={styles.uploadedImage}>
              <img height="100%" style={{ aspectRatio: 1 }} src={image.dataURL} alt="" width="100%" />
              <div style={{ display: "flex", justifyContent: "space-between" }} className="image-item__btn-wrapper">
                <Button className={styles.changeImageButton} buttonType="white" onClick={() => onImageUpdate(index)}>
                  Promeni sliku
                </Button>
                <Button className={styles.changeImageButton} buttonType="red" onClick={() => onImageRemove(index)}>
                  Obrisi sliku
                </Button>
              </div>
            </div>
          ))}
          {initImageList.map((image:any, index:number) => (
            <div key={index} className={styles.uploadedImage}>
              <img height="100%" style={{ aspectRatio: 1 }} src={image} alt="" width="100%" />
              <div style={{ display: "flex", justifyContent: "space-between" }} className="image-item__btn-wrapper">
                <Button className={styles.changeImageButton} buttonType="white" onClick={() => onImageUpdate(index)}>
                  Promeni sliku
                </Button>
                <Button className={styles.changeImageButton} buttonType="red" onClick={() => onImageRemove(index)}>
                  Obrisi sliku
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUploaderWrap;
