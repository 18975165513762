import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchItemsMultiple.module.scss";
import Button from "../../../../Buttons/Button/Button";
import Checkbox from "../../../../Checkbox/Checkbox";

type Props = {
  className?: string;
  style?: any;
  results?: any;
  close?: any;
  onConfirm?: any;
  selectedItemsInitial?: any;
};

type Article = {
  id: number;
  name: string;
};

const SearchItemsMultiple = ({ className, style, results, onConfirm, selectedItemsInitial }: Props) => {
  const [selectedItems, setSelectedItems] = useState<any[]>(selectedItemsInitial || []);

  const [items, setItems] = useState<Article[]>([]);

  const fieldRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (items) {
      setItems([]);

      results &&
        results.forEach((res: Article) => {
          setItems((prev) => [...prev, res]);
        });
    }
  }, [results]);

  const ListItem = ({ item, active, selected, handleSelect }: any) => (
    <div className={`${active ? styles.ItemActive : ""}`} ref={active ? fieldRef : null}>
      <SearchItemMultiple onSelect={(item: any) => handleSelect(item)} selected={selected} item={item} />
    </div>
  );

  const handleSelect = (itemToHandle: any) => {
    const index = selectedItems.findIndex(i => i.id == itemToHandle.id);
    console.log(itemToHandle)
    if (index !== -1) {
      console.log('first')
      setSelectedItems(selectedItems.filter((item) => item.id != itemToHandle?.id));
    } else {
      console.log('second')
      setSelectedItems([...selectedItems, itemToHandle]);
    }
  };

  const handleSelectAll = (itemsToHandle: any[]) => {
    let itemsToHandleDraft = itemsToHandle.filter(item => selectedItems.findIndex(i => i.id == item.id) == -1)
    setSelectedItems([...selectedItems, ...itemsToHandleDraft])
  };
  const selectAll = (items: any[]) => {
      handleSelectAll(items);
  }
  return (
    <div className={`${styles.SearchItems} ${className}`} style={style}>
      <Button onClick={() => selectAll(items)} className={styles.selectAll} buttonType="blue">Izaberi sve</Button>
      {items &&
        items.map((item, i) => {
          return (
            <ListItem handleSelect={handleSelect} selected={selectedItems.find((i:any) => i.id == item.id)} key={i} item={item} />
          );
        })}
      <Button onClick={() => onConfirm(selectedItems)} style={{ position: "sticky", bottom: 0 }} buttonType="blue">
        Potvrdi izbor
      </Button>
    </div>
  );
};

export default SearchItemsMultiple;

type PropsItem = {
  item: any;
  selected?: boolean;
  onSelect?: any;
};

const SearchItemMultiple = ({ item, selected, onSelect }: PropsItem) => {
  return (
    <div
      className={styles.SearchItem}
      onClick={() => {
        onSelect(item);
      }}>
      <span key={item.id} className={styles.SearchItemText}>
        {item.name}
      </span>
      <Checkbox value={!!selected} onChange={() => onSelect(item)} />
    </div>
  );
};
